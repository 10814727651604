/**
 * @fileoverview gRPC-Web generated client stub for org.couchers.api.search
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: search.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var annotations_pb = require('./annotations_pb.js')

var api_pb = require('./api_pb.js')

var communities_pb = require('./communities_pb.js')

var events_pb = require('./events_pb.js')

var groups_pb = require('./groups_pb.js')

var pages_pb = require('./pages_pb.js')
const proto = {};
proto.org = {};
proto.org.couchers = {};
proto.org.couchers.api = {};
proto.org.couchers.api.search = require('./search_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.org.couchers.api.search.SearchClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.org.couchers.api.search.SearchPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.api.search.SearchReq,
 *   !proto.org.couchers.api.search.SearchRes>}
 */
const methodDescriptor_Search_Search = new grpc.web.MethodDescriptor(
  '/org.couchers.api.search.Search/Search',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.api.search.SearchReq,
  proto.org.couchers.api.search.SearchRes,
  /**
   * @param {!proto.org.couchers.api.search.SearchReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.api.search.SearchRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.api.search.SearchReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.api.search.SearchRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.api.search.SearchRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.api.search.SearchClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.api.search.Search/Search',
      request,
      metadata || {},
      methodDescriptor_Search_Search,
      callback);
};


/**
 * @param {!proto.org.couchers.api.search.SearchReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.api.search.SearchRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.api.search.SearchPromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.api.search.Search/Search',
      request,
      metadata || {},
      methodDescriptor_Search_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.api.search.UserSearchReq,
 *   !proto.org.couchers.api.search.UserSearchRes>}
 */
const methodDescriptor_Search_UserSearch = new grpc.web.MethodDescriptor(
  '/org.couchers.api.search.Search/UserSearch',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.api.search.UserSearchReq,
  proto.org.couchers.api.search.UserSearchRes,
  /**
   * @param {!proto.org.couchers.api.search.UserSearchReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.api.search.UserSearchRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.api.search.UserSearchReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.api.search.UserSearchRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.api.search.UserSearchRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.api.search.SearchClient.prototype.userSearch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.api.search.Search/UserSearch',
      request,
      metadata || {},
      methodDescriptor_Search_UserSearch,
      callback);
};


/**
 * @param {!proto.org.couchers.api.search.UserSearchReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.api.search.UserSearchRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.api.search.SearchPromiseClient.prototype.userSearch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.api.search.Search/UserSearch',
      request,
      metadata || {},
      methodDescriptor_Search_UserSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.api.search.EventSearchReq,
 *   !proto.org.couchers.api.search.EventSearchRes>}
 */
const methodDescriptor_Search_EventSearch = new grpc.web.MethodDescriptor(
  '/org.couchers.api.search.Search/EventSearch',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.api.search.EventSearchReq,
  proto.org.couchers.api.search.EventSearchRes,
  /**
   * @param {!proto.org.couchers.api.search.EventSearchReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.api.search.EventSearchRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.api.search.EventSearchReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.api.search.EventSearchRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.api.search.EventSearchRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.api.search.SearchClient.prototype.eventSearch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.api.search.Search/EventSearch',
      request,
      metadata || {},
      methodDescriptor_Search_EventSearch,
      callback);
};


/**
 * @param {!proto.org.couchers.api.search.EventSearchReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.api.search.EventSearchRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.api.search.SearchPromiseClient.prototype.eventSearch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.api.search.Search/EventSearch',
      request,
      metadata || {},
      methodDescriptor_Search_EventSearch);
};


module.exports = proto.org.couchers.api.search;

